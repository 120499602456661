.login-page {
  width: 100%;
  max-width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  box-sizing: border-box;

  & > div {
    margin-bottom: 20px;
  }

  & > button {
    margin-top: 40px;
  }
}
