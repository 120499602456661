.partner-page {
  display: flex;
  flex-direction: column;

  &.register {
  }

  &.list {
  }
  .form__box {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    ul.form-list {
      display: flex;
      flex-direction: column;
      width: 360px;

      & > div {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .button__box {
    width: 360px;
  }
}
