//main-layout
$nav-max-width: 240px;
$header-max-height: 65px;
$wrapper-min-width: 1280px;
$wrapper-max-width: 1600px;
$breakpoint-max-width: 1540px; // wrapper-min-width + nav-max-width + padding 20 x2
$content-full-height: calc(100vh - 145px); // 100vh - header-height + padding 20 x2
//login-layout
$login-layout-max-width: 450px;

//border
$border-radius: 6px;

//base-font-size
$base-font-size: 14px;
$breakpoint-font-size: 12px;
