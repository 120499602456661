.main-navigation-header {
  // min-height: $header-max-height;
  height: $header-max-height;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $base-color;
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px $border-color;
  box-sizing: border-box;
}

.main-navigation {
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  flex: 1;
}

.nested-navigation {
  .active {
    span {
      color: $base-color;
      font-weight: 600;
    }
  }
}
