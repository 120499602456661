.login-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > section {
    width: 100%;
    max-width: $login-layout-max-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px $border-color;
    &.header {
      height: 100px;
      justify-content: center;
      border-top-right-radius: $border-radius;
      border-top-left-radius: $border-radius;

      div.logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        b {
          font-size: 24px;
          color: $base-color;
        }
      }
    }

    &.content {
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      border-top: none;
      margin-bottom: 100px;
    }
  }
}
