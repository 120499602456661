* {
  font-size: $base-font-size;
}

.main-layout {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  & > article {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.navigation-wrapper {
      display: flex;
      max-width: $nav-max-width;
      min-width: $nav-max-width;
      border-right: solid 1px $border-color;
      box-sizing: border-box;
    }

    &.content-wrapper {
      overflow: hidden;
      display: flex;

      .header {
        display: flex;
        // min-height: $header-max-height;
        height: $header-max-height;
        background: $base-color;
        justify-content: space-between;
        section {
          display: flex;
          align-items: center;

          .router-text {
            color: #fff;
            font-size: 16px;
            margin-left: 40px;
            height: 14px;
            line-height: 17px;
            font-weight: bold;
          }
        }

        .MuiIconButton-root {
          width: $header-max-height;
          height: 100%;

          &.toggle-nav {
            display: none;
          }
          .MuiSvgIcon-root {
            width: 30px;
            height: 30px;
            color: #fff;
          }
        }
      }

      .scroll__box {
        overflow-y: auto;
        display: flex;
        justify-content: center;
        height: auto;
        padding: 40px;
        box-sizing: border-box;
        .content {
          min-width: $wrapper-min-width;
          max-width: $wrapper-max-width;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max-width) {
  .main-layout {
    & > article {
      &.navigation-wrapper {
        display: none;
      }
      &.content-wrapper {
        .header {
          .MuiIconButton-root.toggle-nav {
            display: block;
          }
          section {
            .router-text {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
}
