.pharm-page {
  width: 100%;

  &.wait {
    .table__container {
      margin-bottom: 40px;
    }
  }

  .filter__container {
    margin-bottom: 40px;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .search__box {
      display: flex;
      align-items: flex-end;

      button {
        margin-left: 10px;
      }
    }

    .select__box {
    }
  }

  .table__container {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    .MuiPaper-rounded {
      overflow: hidden;
    }

    table {
    }
    thead {
      th {
        padding: 10px 10px;
      }
    }
    tbody {
      td {
        max-width: 100px;
      }
    }
  }

  .pagination__container {
    margin: 40px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &.detail {
    display: flex;
    height: $content-full-height;
  }

  .detail__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: hidden;
    &:last-child {
      margin-right: 0px;
    }

    &:first-child {
      max-width: 460px;
    }

    .header__box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
    }
  }

  .form__box {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding: 10px 0px;

    ul.form-list {
      display: flex;
      flex-direction: column;
      width: 100%;

      & > div {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .action__box {
    display: flex;
    justify-content: center;
  }
}
